<template>
  <UCard class="grow">
    <UForm
      id="organization-form"
      :state="state"
      autocomplete="off"
      @submit="submit"
    >
      <div class="flex justify-between items-start gap-x-4">
        <div class="flex-auto flex flex-col gap-y-4">
          <UFormGroup
            name="name"
            label="Organization Name"
            :hint="$store.organization?.name"
          >
            <UInput
              v-model="state.name"
              autocomplete="off"
            />
          </UFormGroup>
          <UFormGroup
            label="Closing Date"
            :hint="filters.formatLongDate($store.organization?.date ?? undefined)"
          >
            <UInput
              :value="state.date"
              type="date"
            />
          </UFormGroup>
        </div>
        <div class="flex-auto flex flex-col items-center">
          <UFormGroup
            name="logo"
          >
            <template #label>
              <UTooltip
                text="Select File"
                :popper="{ placement: 'auto' }"
                class="flex flex-col items-center gap-y-2 cursor-pointer"
              >
                Logo
                <UAvatar
                  size="3xl"
                  :src="state.logoUrl ?? undefined"
                  img-class="object-cover"
                />
              </UTooltip>
            </template>
            <UInput
              type="file"
              accept="image/*"
              class="hidden"
              @change="changeLogo"
            />
          </UFormGroup>
        </div>
      </div>

      <UFormGroup
        label="Description"
        class="mt-4"
      >
        <MdEditor
          v-model="state.description"
          language="en-US"
          no-iconfont
          class="size-full"
        />
      </UFormGroup>
    </UForm>
    <template #footer>
      <div class="grid grid-cols-2 gap-x-4">
        <UButton
          size="xl"
          color="white"
          @click="cancel"
        >
          Cancel
        </UButton>
        <UButton
          type="submit"
          size="xl"
          form="organization-form"
        >
          Save
        </UButton>
      </div>
    </template>
  </UCard>
</template>

<script setup lang="ts">
import type { Database } from '~/supabase/types'
import { MdEditor } from 'md-editor-v3'
import { config } from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'
import '~/assets/common/md.css'

config({
  iconfontType: 'class'
});

const { $store } = useNuxtApp()
const state = reactiveComputed(() => {
  const { name, logo, date, description, logoUrl } = $store.organization || {}
  return { 
    name, 
    logo: logo ?? undefined, 
    date: date ?? undefined, 
    description: description ?? undefined,
    logoUrl,
    file: undefined as File | undefined
  }
})

async function changeLogo(event : { target: HTMLInputElement }) {
  const file = event.target.files?.[0]
  if (file) {
    state.file = file
    state.logo = file?.name
    const url = URL.createObjectURL(file)
    state.logoUrl = url
  }
}

function cancel() {
  const { name, logo, date, description, logoUrl } = $store.organization || {}
  state.name = name
  state.logo = logo ?? undefined
  state.date = date ?? undefined
  state.description = description ?? undefined
  state.logoUrl = logoUrl
  state.file = undefined
}

async function submit() {
  const organization = $store.organization
  if (!organization) {
    return
  }
  const id = organization.id
  const supabase = useSupabaseClient<Database>()

  if (state.file) {
    // First remove the old file
    const { error: delErr } = await supabase
      .storage
      .from('media')
      .remove([`${id}/${organization.logo}`])
    if (delErr) {
      return showError(delErr)
    }

    // Then upload the new one
    const { error: uplErr } = await supabase
      .storage
      .from('media')
      .upload(`${id}/${state.logo}`, state.file, {
        cacheControl: '3600',
        upsert: true
      })
    if (uplErr) {
      return showError(uplErr)
    }
  }

  // Update the organization in database
  const record = {
    name: state.name,
    date: state.date,
    description: state.description,
    logo: state.logo
  }
  const { error } = await supabase
    .from('organizations')
    .update(record)
    .eq('id', id)
  if (error) {
    const { $toast } = useNuxtApp()
    $toast.error(error.message)
  }
}
</script>
